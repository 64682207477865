.eco__card__img {
    position: relative;
    width: 50%;
    left: 50px;
    right: 50px;
    text-align: center;
  }

.eco__card__img img {
    height: 270px;
    width: 285px;
    transform: translate(-42%, -1%);
    position: absolute;
    /* top: 39%; */
    align-items: center;
    /* border-radius: 50%; */
    /* border: 1px solid #4D9621; */
    /* padding: 12px; */
}
.eco__overlay__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
  }
  
  .eco__card__img:hover .eco__overlay__img {
    opacity: 1;
  }

.circle-wrapper h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -103%); */
    transform: translate(-42%, 50%);
    text-align: center;
    margin-top: 45px;
    color: #fff;
    word-wrap: normal;
}
.circle-wrapper h5 {
    /* margin-top: 12px; */
    font-size: 15px;
    /* color: rgb(155, 153, 153); */
}

.circle__body {
    background-image: url("../../images/Logo__img1.png");
    background-repeat: no-repeat;
    margin: 100px auto;
    background-size: 240px auto;
    /* border: 2px solid green; */
    width: auto 265px;
    height: 265px;
    border-radius: 51%;
}

.circle__padding {
    /* padding: 13px 6px; */
    padding: 46px 6px;
}

.circle-wrapper {
    /* background-image: url("../../images/Logo__img1.png");
    background-repeat: no-repeat; */
    /* background-size: cover; */
    width: 265px;
    height: 265px;
    border-radius: 51%;
    /* background:Chartreuse; */
    background:#4D9621;
    position: relative;
    margin: 100px auto;
    /* background-size: 240px auto;
    border: 2px solid green; */
}

.circle {
    display: block;
    position: absolute;
    top: 51%;
    left: 51%;
    width: 120px;
    height: 120px;
    margin: -70px;
    background: #fff;
    border-radius: 51%;
    text-align: center;
    border: 2px solid #4D9621;
    /* border: 2px solid rgb(155, 153, 153); */
    /* line-height: 100px;
    word-wrap: break-word; */
}


.deg-0 {
    transform: translate(-290px)
}

.deg-25 {
    transform: rotate(26deg) translate(-290px) rotate(-26deg);
}

.deg-52 {
    transform: rotate(53deg) translate(-290px) rotate(-53deg);
}

.deg-78 {
    transform: rotate(79deg) translate(-290px) rotate(-79deg);
}

.deg-103 {
    transform: rotate(104deg) translate(-290px) rotate(-104deg);
}

.deg-129 {
    transform: rotate(130deg) translate(-290px) rotate(-130deg);
}

.deg-154 {
    transform: rotate(155deg) translate(-290px) rotate(-155deg);
}

.deg-180 {
    transform: rotate(181deg) translate(-290px) rotate(-181deg);
}

.deg-205 {
    transform: rotate(206deg) translate(-290px) rotate(-206deg);
}

.deg-231 {
    transform: rotate(232deg) translate(-290px) rotate(-232deg);
}

.deg-256 {
    transform: rotate(257deg) translate(-290px) rotate(-257deg);
}

.deg-282 {
    transform: rotate(283deg) translate(-290px) rotate(-283deg);
}

.deg-307 {
    transform: rotate(308deg) translate(-290px) rotate(-308deg);
}

.deg-333 {
    transform: rotate(334deg) translate(-290px) rotate(-334deg);
}



@media only screen and (max-width: 768px) {
    .circle-wrapper h2 {
        margin-top: 17px;
        font-size: 15px;
    
    }
    .circle-wrapper h5 {
        margin-top: 20px;
        font-size: 13px;
        /* color: rgb(155, 153, 153); */
    }

    .circle__body {
        background-image: url("../../images/Logo__img1.png");
        background-repeat: no-repeat;
        margin: 10px auto;
        background-size: 120px;
        border: 2px solid green;
        width: 120px;
        height: 120px;
        border-radius: 0%;
    }

    .circle__padding {
        padding: 10px;
    }

    .circle-wrapper {
        width: 125px;
        height: 125px;
        /* border-radius: 51%; */
    }

    .circle {
        display: block;
        position: absolute;
        top: 51%;
        left: 51%;
        width: 80px;
        height: 80px;
        margin: -47px;
        background: #fff;
        border-radius: 51%;
        text-align: center;
        border: 0px solid rgb(155, 153, 153);
    }


    .deg-0 {
        transform: translate(161px)
    }

    .deg-27 {
        transform: rotate(28deg) translate(161px) rotate(-28deg);
    }

    .deg-54 {
        transform: rotate(55deg) translate(161px) rotate(-55deg);
    }

    .deg-81 {
        transform: rotate(82deg) translate(161px) rotate(-82deg);
    }

    .deg-108 {
        transform: rotate(109deg) translate(161px) rotate(-109deg);
    }

    .deg-135 {
        transform: rotate(136deg) translate(161px) rotate(-136deg);
    }

    .deg-162 {
        transform: rotate(163deg) translate(161px) rotate(-163deg);
    }

    .deg-189 {
        transform: rotate(190deg) translate(161px) rotate(-190deg);
    }

    .deg-216 {
        transform: rotate(217deg) translate(161px) rotate(-217deg);
    }

    .deg-243 {
        transform: rotate(244deg) translate(161px) rotate(-244deg);
    }

    .deg-271 {
        transform: rotate(272deg) translate(161px) rotate(-272deg);
    }

    .deg-299 {
        transform: rotate(300deg) translate(161px) rotate(-300deg);
    }

    .deg-327 {
        transform: rotate(328deg) translate(161px) rotate(-328deg);
    }
    .deg-353 {
        transform: rotate(356deg) translate(161px) rotate(-356deg);
    }
}

/* 
.circle-wrapper {
    width:601px;
    height:601px;
    border-radius:51%;
    background:Chartreuse;
    position:relative;
    margin:100px auto;
 }
 
 .circle {
    display:block;
    position:absolute;
    top:51%;
    left:51%;
    width:30px;
    height:30px;
    margin:-16px;
    background:red;
    border-radius:51%;
    text-align:center;
    line-height:100px;
 }

 
 .deg-0 {
    transform:translate(301px)
 }
 
 .deg-25 {
    transform:rotate(26deg) translate(301px) rotate(-26deg);
 }
 
 .deg-50 {
    transform:rotate(51deg) translate(301px) rotate(-51deg);
 }
 
 .deg-75 {
    transform:rotate(76deg) translate(301px) rotate(-76deg);
 }
 
 .deg-100 {
    transform:rotate(101deg) translate(301px) rotate(-101deg);
 }
 
 .deg-125 {
    transform:rotate(126deg) translate(301px) rotate(-126deg);
 }
 
 .deg-150 {
    transform:rotate(151deg) translate(301px) rotate(-151deg);
 }
 
 .deg-175 {
    transform:rotate(176deg) translate(301px) rotate(-176deg);
 }
 .deg-200 {
    transform:rotate(201deg) translate(301px) rotate(-201deg);
 }
 .deg-225 {
    transform:rotate(226deg) translate(301px) rotate(-226deg);
 }
 .deg-250 {
    transform:rotate(251deg) translate(301px) rotate(-251deg);
 }
 .deg-275 {
    transform:rotate(276deg) translate(301px) rotate(-276deg);
 }
 .deg-300 {
    transform:rotate(301deg) translate(301px) rotate(-301deg);
 }
 .deg-325 {
    transform:rotate(326deg) translate(301px) rotate(-326deg);
 } */