.footer__bg {
    background-color: #2C2D2D;
    margin-top: 60px;
}

/* .footer_bg_img{
    background-image: url("../../images/footer_bg.png");
    background-repeat: no-repeat;
    background-size: 5000px;
} */
.header__footer {
    line-height: 180%;
}

.footer__description {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 173%;
    text-align: justify;
}

.contact__us__address {
    font-style: normal;
    font-weight: normal;
    color: #8b8d8f;
    font-size: 14px;
    line-height: 173%;
    margin-bottom: 5px !important;
}

.footer_social_icon {
    margin-top: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: start;
}

.header_link_footer {
    line-height: 180%;
    color: #fff;
}

.header_link_footer span {
    border-bottom: 1px solid #fff;
}

.link_footer {
    line-height: 180%;
    text-decoration: none;
    margin-bottom: 4px !important;
}

.link_footer p {
    margin-bottom: 4px !important;
    font-size: 14px;
}

.text__color {
    color: #fff !important;
}

.social__text {
    color: #fff;
}

.subscribe {
    background: #5AAB46;
    text-align: center;
    color: white;
    border-radius: 10px;
    width: 100%;
    padding: 0.375rem .75rem;
}

.subscribe:hover {
    background: #5AAB46;
    color: white;
    border-radius: 10px;
    width: 100%;
}

.header_contact_us_footer {
    line-height: 180%;
    color: #0D2C49;
}

.contact_us_footer {
    line-height: 180%;
}

.footer_bg {
    background: #EDF4FF;
}

.LOGO_01__footer img {
    width: 100%;
    height: 125px;
}

.footer__copyright {
    font-size: small;
    background: #EBEBEB;
    color: #484848;
    /* padding: 10px; */

}

.footer_copyright {
    text-align: center;
    font-size: small;
    background: #EBEBEB;
    color: #484848;
    padding: 10px;

}

.footer__social__img img {
    border: 1px solid #fff;
    border-radius: 15px;
    width: 170px;
    height: 55px;
    opacity: 50%;
}

/* start  */
.ht-social a {
    margin-top: 14px;
    background: #fff;
    color: #515151;
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-size: 15px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #515151;
}

.ht-social .facebook:hover {
    background: #4267B2;
    color: #fff;
}

.ht-social .twitter:hover {
    background: #1DA1F2;
    color: #fff;
}

.ht-social .linkedin:hover {
    background: #0077B5;
    color: #fff;
}

.ht-social .whatsapp:hover {
    background: #25D366;
    color: #fff;
}

.ht-social .instagram:hover {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    color: #fff;
}

.ht-social .pinterest:hover {
    background: #E60023;
    color: #fff;
}

.ht-social .youtube:hover {
    background: #E60023;
    color: #fff;
}

.ht-social .google:hover {
    background: #DB4437;
    color: #fff;
}

/* end */

/* .social__media {
    margin-top: 112px;
} */
.quick__link__footer {
    padding-right: 65px;
}

.contact__footer {
    display: flex;
    align-items: center;
}


.foot__text__color {
    color: #0d2c49c4 !important;
}









@media only screen and (max-width: 768px) {
    .contact__footer {
        display: flex;
        align-items: center;
    }

    .footer_bg_img {
        background-image: url("../../images/footer_bg.png");
        background-size: 5000px;
        background-repeat: no-repeat;
    }

    .LOGO_01__footer img {
        width: 100%;
        height: 90px;
        padding: 0px 80px;
    }

    .social__text {
        text-align: center;
        font-size: 18px;
    }

    .quick__link__footer {
        text-align: center;
    }

    .header_link_footer {
        text-align: center;
        font-size: 18px;
    }

    .social__media {
        margin-top: 0px;
    }

    .footer_social_icon {
        text-align: center;
    }

    .quick__link__footer {
        padding-right: 0px;
    }

    .office__address {
        padding: 0px 0px 0px 58px;
    }

    /* start  */
    .ht-social {
        text-align: center;
    }

    .ht-social a {
        height: 25px;
        line-height: 21px;
        width: 25px;
        font-size: 15px;
    }

    /* end */


    .footer__social__img {
        justify-content: center;
        display: flex;
    }
}



.footer-list {
    color: #869099;
    transition: color 0.2s;
    cursor: pointer;
    font-weight: normal;
}

.footer-list:hover {
    color: #69B128;
}

.condition-privacy li {
    margin: 10px 0 0 0;
}

.condition-privacy a {
    color: #69B128 !important;
    text-decoration: none;

}

.condition-privacy a:hover {
    text-decoration: underline;

}

.condition-privacy h5 {
    margin: 20px 0 10px 0;
    padding: 0;
}

.custom-ordered-list {
    list-style-type: none;
    counter-reset: my-counter;
}

.custom-ordered-list>li::before {
    content: counter(my-counter) ") ";
    counter-increment: my-counter;
}

ol li.custom-style {
    list-style-type: lower-alpha;
    list-style: none;
}

li {
    margin-bottom: 10px;
}

li.custom-style {
    font-weight: bold;
    color: blue;
}

.bn-terms p {
    line-height: 35px;
}