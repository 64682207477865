.submenu-list {
  /* background: #ccc; */
  width: 100%;
  /* padding: 10px 40px; */
}

.sub-menu {
  /* background: green; */
  /* border: 1px solid #333333; */
  display: none;
}

.sub-menu.is-open {
  display: block;
}

.prm__btn {
  margin: 10px 0px;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
}

.prm__btn p {
  margin-bottom: 0px !important;
}

.prm__drop__title {
  font-weight: 600;
  border: 0;
  font-size: 15px;
  color: #333333;
}

.prm__drop__body {
  /* padding-right: 20px; */
  padding: 7px 15px;
}

.prm__img {
  text-align: center;
}

.prm__img img {
  padding-right: 80px;
  width: 95%;
  height: 420px
}
.prm__description {
    line-height: 173%;
    font-size: 14px;
    margin-top: 8px;
}

@media only screen and (max-width: 768px) {
  .prm__img img {
    padding-right: 0px;
    width: 80%;
    height: 290px;
  }
  .prm__btn {
    margin: 2px 0px;
    padding: 7px 15px;
  }
  .prm__drop__title {
    font-size: 15px;
    color: #333333;
  }
  .prm__description {
    line-height: 130%;
}

}