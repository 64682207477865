@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap");

* {
  font-family: "Roboto", normal;
  /* margin-bottom: 0px !important; */
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #4d9621;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bg__body {
  background-color: #ffffff;
}

/* .nav-link {
  color: #413e3e;
} */
.btn__read__more {
  background: #5aab46;
  border-radius: 10px;
  font-size: 14px;
  padding: 6px 20px;
  color: white !important;
}

.btn__read__more:hover {
  color: white !important;
}

.header__text {
  font-weight: 400;
}

.header__text__martplace {
  font-weight: 400;
}

.h__text__color {
  color: #5aab46;
}

.header__description2 {
  color: #0d2c49;
  line-height: 173%;
  font-size: 17px;
  text-align: justify;
}

.header__description {
  color: #0d2c49;
  line-height: 150%;
  font-size: 17px;
  text-align: justify;
}

.mpms__mt {
  margin-top: 20px;
}

.header__description .green__color {
  color: #5aab46;
}

.header__text__normal {
  color: #0d2c49;
}

/* ul li{
padding-left: 5px;
line-height: 173%;
font-size: 15px;
} */

.home__banner {
  position: relative;
  color: white;
}

.banner__img img {
  width: 100%;
  height: 500px;
}

.banner__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-85%, 60%);
  border-left: 3px solid #ff5733;
  padding-left: 15px;
}

.banner__title2 {
  text-align: start;
  color: #fff;
  font-weight: 600;
}

.banner__title {
  text-align: start;
  color: white;
  font-size: 22px;
  font-weight: 600;
}

.banner__subtitle {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 140%;
}

.banner__description {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 140%;
}

.h__banner {
  background-position: fixed;
  background-image: url("./images/home_banner.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 400px;
  z-index: 0;
}

.banner__txt {
  position: relative;
  border-left: 3px solid #f68823;
  padding-left: 20px;
  margin-top: 120px;
  z-index: 1;
}

.macro {
  margin: 0px 115px 0px 115px;
}

.lab__door {
  margin: 0px 35px;
}

.neurologist {
  margin: 0px 65px;
}

.dermotologist p {
  margin-bottom: 5px !important;
  font-size: 16px;
  font-weight: 400;
  color: #0d2c49;
}

.solution__macro,
.testimonials {
  text-align: center;
}

.macrohealth__card {
  min-height: 100%;
}

.macrohealth__card .card {
  min-height: 305px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.macrohealth__card img {
  width: 70px;
  height: 75px;
}

.macrohealth__card .macrohealth__mt {
  margin-top: 25px;
}

.testi__margin__top {
  margin-top: 120px;
}

.header__card__title {
  color: #ff0000;
  font-weight: 500;
  line-height: 120%;
  font-size: 17px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.header__card__subtitle2 {
  color: #5aab46;
  font-weight: 500;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
  min-height: 40px !important;
  max-height: 50px !important;
  overflow: hidden;
}

.header__card__subtitle {
  color: #5aab46;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  min-height: 25px !important;
  max-height: 20px !important;
  overflow: hidden;
}

.header__card__discription {
  color: #0d2c49c4;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 140%;
  min-height: 50px !important;
  max-height: 125px !important;
  overflow: hidden;
}

.features__mpl__card .card {
  min-height: 200px !important;
  max-height: 100px !important;
  background-color: #5aab46;
  border: 0px solid #5aab46 !important;
}

.features__mpl__card__title {
  color: #fff;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  padding: 10px 0px;
  text-align: center;
  border-bottom: 4px solid #fff;
  margin-bottom: 0rem !important;
}

.features__mpl__card__even__title {
  color: #fff;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  padding: 10px 0px;
  text-align: center;
  margin-bottom: 0rem !important;
}

.features__mpl__card__description {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  min-height: 50px !important;
  max-height: 125px !important;
  overflow: hidden;
}

.about__img img {
  width: 100%;
  height: 450px;
  padding-right: 90px;
}

.great__doc {
  min-height: 215px !important;
  max-height: 100px !important;
  overflow: hidden;
}

.great__doc ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.great__doc ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.medical__practice {
  min-height: 130px !important;
  max-height: 80px !important;
  overflow: hidden;
}

.medical__practice ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.medical__practice ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.mp__card__body {
  padding: 0px 10px;
}

.feature__img__2 img {
  width: 100%;
  height: 440px;
}

/* slider  */

.web__carousel__box__card .card {
  min-height: 120px !important;
  text-align: start;
  max-height: 100px !important;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  border-radius: 20px;
  z-index: 1;
}

.web__carousel__box__card__discription {
  color: #000000;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 145%;
  font-family: "Raleway", sans-serif;
  min-height: 120px !important;
  max-height: 130px !important;
  overflow: hidden;
}

.web__carousel {
  /* overflow: hidden; */
  padding: 0px 30px;
}

.web__carousel .slick-prev:before,
.web__carousel .slick-next:before {
  color: #484848 !important;
  font-size: 30px !important;
}

.web__carousel .slick-slider .slick-slide {
  padding: 0 6px;
}

.web__carousel .slick-slider,
.slick-list,
.slick-track {
  height: 100%;
  margin-left: 5px !important;
}

.web__carousel .slick-dots li {
  margin: 0px 0px !important;
}

.web__carousel .slick-dots li button:before {
  font-size: 8px !important;
}

.testimonials__carousel__box__card {
  min-height: 100%;
}

.testimonials__carousel__box__card .card {
  min-height: 315px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  border: none !important;
  z-index: 1;
}

.tesimonials_h {
  margin: 0px 115px 0px 115px;
}

.testimonials__inside__card {
  justify-content: center;
}

.testimonials__inside__card .card2 {
  min-height: 100px !important;
  max-height: 100px !important;
  background: #ffffff;
  padding: 0px 350px;
  z-index: 1;
}

.testimonials__img img {
  height: 120px;
  width: 100%;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 40px 15px rgb(0 0 0 / 10%);
}

.testimonials__card__title {
  font-size: 20px;
  font-weight: 600;
  color: #0d2c49;
  text-align: center;
}

.testimonials__card__subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #0d2c49;
  text-align: center;
}

.testimonials__carousel__card__title {
  color: #5aab46;
  font-weight: 500;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0rem !important;
}

.testimonials__carousel__discription {
  color: #000000;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 173%;
  font-family: "Raleway", sans-serif;
  min-height: 120px !important;
  max-height: 130px !important;
  overflow: hidden;
}

.world__partner__carousel {
  padding: 0 20px;
}

.world__partner__carousel .slick-prev:before,
.slick-next:before {
  color: #484848 !important;
}

.world__partner__carousel .slick-slider .slick-slide {
  padding: 0px 9px;
  align-items: center;
}

.world__partner__carousel .slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.call__us__img img {
  width: 205px;
  height: 160px;
}

.call__us {
  margin: 20px 0px;
}

.call__us__text {
  /* margin-top: 50px; */
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}

.call__us__text__no {
  /* margin-top: 50px; */
  padding-left: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}

.news__letter .form-control {
  border: 1px solid #5aab46 !important;
  border-radius: 8px;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.news__letter .news__subscribe {
  background: #5aab46;
  text-align: center;
  color: white;
  border: 1px solid #5aab46 !important;
  border-radius: 10px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.request__demo__btn {
  background: #264868;
  text-align: center;
  color: white;
  border: 1px solid #264868 !important;
  border-radius: 10px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.request__demo__btn:hover {
  background: #264868;
  color: white;
}

.mt__req__demo {
  margin-top: 25px;
}

.mt__req {
  margin-top: 30px;
}

.mims__banner {
  background-image: url("../src/images/MimsBanner.jpg");
  background-repeat: no-repeat;
  height: 400px;
}

.mht__excited {
  margin-top: 20px;
  margin-bottom: 30px;
}

.mht__rms {
  margin-top: 40px;
}

.mht__excited__description {
  font-size: 23px;
  text-align: start;
  color: #fff;
  padding-right: 145px;
}

.request__demo__btn2 {
  background: #fff;
  text-align: center;
  color: #4d9621;
  border: 1px solid #fff !important;
  border-radius: 10px;
  padding: 12px 30px;
  border-color: inherit;
  box-shadow: none;
  text-decoration: none;
}

.request__demo__btn2:hover {
  background: #fff;
  color: #4d9621;
  text-decoration: none;
}

.mht__mims__img img {
  width: 100%;
  height: 360px;
}

.mht__excited__img img {
  width: 100%;
  height: 100px;
}

.mb__req {
  margin-bottom: 10px;
}

.hreq__text2 {
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.hreq__text {
  font-weight: 600;
  text-align: center;
  color: #264868;
}

.hreq__subtext {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.hreq__about {
  margin-bottom: 10px;
}

.hreq__about__text {
  font-weight: 600;
  text-align: start;
  color: #fff;
}

/* contact us */

.banner_contact_us {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}

.banner_contact_us .content_img {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.22);
  color: hsla(0, 23%, 95%, 0.856);
  width: 100%;
  padding: 30px;
}

.header_get_touch,
.header_contact_us {
  line-height: 180%;
  padding-bottom: 20px;
}

.header__link {
  line-height: 180%;
  color: #2a455f;
}

.contact_us_description {
  font-style: normal;
  font-weight: normal;
  color: #8b8d8f;
  font-size: 14px;
  line-height: 173%;
  margin-bottom: 5px !important;
}

.contact__us .col-form-label,
.contact__us .form-control,
.contact__us .form-check-label {
  color: #8b8d8f;
  font-size: 14px !important;
  font-weight: normal;
}

.contact_us_social_icon {
  color: #2a93c0;
}

.btn_free_trail_now {
  background: #2a93c0;
  border-radius: 10px;
  color: white;
}

.text_color {
  line-height: 180%;
  color: #2a93c0;
}

.btn_free_trail_now:hover {
  color: white !important;
}

.contact__us .form-control-sm {
  background: #e3e8ee !important;
  color: #4c4e4f;
}

.contact__us .form-control:focus,
.contact__us .form-select-sm:focus {
  border-color: #e3e8ee !important;
  box-shadow: none !important;
}

.select__drop .form-select-sm {
  background-color: #e3e8ee !important;
  font-size: 14px;
  font-weight: 400;
  color: #4c4e4f;
}

.contact__world {
  position: relative;
  color: white;
}

.contact__world__map {
  padding: 0px 90px;
}

.contact__world__map img {
  width: 100%;
  height: 500px;
}

.contact__world__overlay__div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-48%, -7%);
}

.contact__address1 {
  padding: 10px 10px;
  /* background: #F6F6F6; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contact__address2 {
  padding: 10px 10px;
  /* background: #F6F6F6; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.addr__icons__color {
  color: #2a455f;
}

.addr__text {
  font-style: normal;
  font-weight: normal;
  color: #2a455f;
  font-size: 14px;
  line-height: 173%;
  margin-bottom: 5px !important;
}

.address__description {
  font-style: normal;
  font-weight: normal;
  color: #727476;
  font-size: 14px;
  line-height: 173%;
  margin-bottom: 5px !important;
}

.address__title {
  text-align: start;
  color: #2a455f;
  font-size: 15px;
  font-weight: 600;
}

.btn__contact {
  background: #4d9621;
  border-radius: 8px;
  padding: 6px 30px;
  color: white !important;
}

.contact__us .form-check-input:focus {
  box-shadow: none !important;
}

.request__demo {
  margin: 0px 128px;
  padding: 20px;
  background: #4d9621;
  border-radius: 50px;
}

.request__demo__text {
  color: #fff;
  text-align: center;
  margin-bottom: 0px !important;
}

.bg__demo {
  background: #264868;
  border-radius: 10px;
}

.request__demo__left {
  padding: 0px 40px;
}

.header__demo__title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.header__demo__description {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
  line-height: 160%;
  margin-bottom: 5px !important;
}

.demo__list ul {
  padding-left: 17px !important;
}

.demo__list ul li {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  line-height: 173%;
}

.btn__request {
  background: #4d9621;
  border-radius: 8px;
  padding: 6px 30px;
  font-size: 15px;
  color: #fff !important;
  border-color: #4d9621;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: none;
  width: 100%;
}

/* contact us */

/* helpcenter start  */
.helpc__description {
  padding-top: 10px;
  font-size: 15px;
  line-height: 173%;
  text-align: justify;
  overflow: hidden;
  color: #737373;
}

.help__centre__accrodian .accordion-collapse {
  border: 0;
}

.help__centre__accrodian .accordion-button {
  padding: 5px 14px;
  font-weight: bold;
  border: 0;
  font-size: 14px;
  color: #000000;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.help__centre__accrodian .accordion-button:focus {
  box-shadow: none;
  border: none;
}

.help__centre__accrodian .accordion-button:not(.collapsed) {
  background: none;
  color: #dc3545;
}

.help__centre__accrodian .accordion-body {
  padding: 15px;
  background-color: #f6f6f6;
}

.help__centre__accrodian .accordion-button::after {
  width: auto;
  height: auto;
  content: "+";
  font-size: 24px;
  background-image: none;
  font-weight: 300;
  color: #5caf04d7;
  /* transform: translateY(-4px); */
}

.help__centre__accrodian .accordion-button:not(.collapsed)::after {
  width: auto;
  height: auto;
  background-image: none;
  content: "-";
  font-size: 35px;
  transform: translate(-5px, -4px);
  transform: rotate(0deg);
}

/* .helpc__searchbar .MuiPaper-elevation1 {
  box-shadow: none;
  border: 2px solid #c6cec6;
  border-radius: 300px;
} */
.helpc__searchbar .MuiIconButton-root {
  flex: 0 0 auto;
  color: rgb(100 95 95 / 54%);
  padding: 4px 8px;
}

.helpc__searchbar .MuiFormControl-root {
  align-items: center;
}

.helpc__searchbar .PrivateNotchedOutline-root-8 {
  top: -5px;
  border: 2px solid #d9d9d9;
  border-radius: 35px;
}

.helpc__searchbar .PrivateNotchedOutline-root-1 {
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border: 2px solid #d9d9d9;
  border-radius: 35px;
  /* border-style: solid;
  border-width: 1px;
  border-radius: inherit; */
  pointer-events: none;
}

.helpc__searchbar .MuiInputBase-fullWidth {
  width: 60%;
}

.helpc__searchbar .MuiOutlinedInput-input {
  padding: 25px 2px;
}

.helpc__searchbar .MuiSvgIcon-root {
  font-size: 45px;
}

.helpc__searchbar .MuiOutlinedInput-adornedStart {
  padding-left: 10px;
}

.helpc__searchbar__card .card {
  min-height: 250px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  /* box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 5%); */
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.heahelpc__card__discription {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 120%;
  overflow: hidden;
  margin-bottom: 5px !important;
}

.heahelpc__card__subtitle {
  color: #212529;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  min-height: 35px !important;
  max-height: 50px !important;
  overflow: hidden;
}

/* .centre__bg {
  position: absolute;
} */
/* .help__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-85%, 60%);
  border-left: 3px solid #FF5733;
  padding-left: 15px;
} */
.centre__bg__div {
  position: absolute;
}

.centre__bg img {
  position: relative;
  /* top: -180px; */
  left: 143px;
  height: 50px;
}

/* .searcht__icon{
  top: 17px;
  position: relative;
} */
.searcht__icon {
  top: 17px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

/* .searcht__icon__right img{
  padding: 0px 0px 0px 545px;
  top: 25px;
  right: -90px;
  position: relative;
}  */

.searcht__icon__left {
  /* padding: 0px 0px 0px 545px; */
  top: -16px;
  left: 145px;
  position: relative;
}

.searcht__icon__right {
  /* padding: 0px 0px 0px 545px; */
  top: 25px;
  right: 43px;
  position: relative;
}

.searchb__icon {
  margin-top: 35px;
  padding: 0px 70px;
}

.searchb__icon__right {
  padding: 0px 40px 0px 0px;
}

.help__ft__text {
  color: #2face1;
}

.ft__helthcentre {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ft__helthcentre p {
  padding-right: 8px;
}

/* helpcenter end  */

/* ehr start */
.pharmacy {
  margin-top: 0px;
}

.ehr {
  margin-top: 45px;
}

.btn__ehr {
  background: #5aab46;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 15px;
  color: white !important;
  text-decoration: none;
}

.ehr__img {
  padding-left: 10px;
  text-align: center;
}

.ehr__img1 img {
  width: 100%;
  height: 390px;
}

.pr__img img {
  width: 100%;
  height: 395px;
}

.maintain__vital__img {
  padding-left: 35px;
}

.maintain__vital__img img {
  width: 100%;
  height: 330px;
  padding: 0px 125px;
}

.mpm__margin {
  margin-top: 95px;
}

.mpm {
  margin-top: 110px;
}

.mpm__img {
  position: relative;
  text-align: center;
  color: white;
}

.mpm__img img {
  width: 100%;
  height: 330px;
}

.mpm__overlay__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mpm__youtube__icons {
  background: #0d2c49;
  border-radius: 10px;
  padding: 0px 10px;
}

.mpm__youtube__icons img {
  width: 16px;
  height: 19px;
  margin: 10px;
}

.app__mangement__img img {
  height: 80px;
  width: 100%;
  align-items: center;
}

.features__ehr {
  padding: 0px 90px;
}

.features__ehr__card .card {
  min-height: 190px !important;
  max-height: 100px !important;
  /* background-color: #FBFFF8; */
  background-color: #ffffff;
  border: 0px solid #fff !important;
}

.features__ehr__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.enrich__card .card {
  min-height: 160px !important;
  max-height: 100px !important;
  /* background-color: #FBFFF8; */
  background-color: #ffffff;
  border: 0px solid #fff !important;
}

/* .enrich__card__img{
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #4D9621;
} */
.enrich__card__img img {
  height: 90px;
  width: 90px;
  align-items: center;
  /* border-radius: 50%; */
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}

/* .enrich__card__img img:hover{
  color: #fff;
  background: #258a07;
  fill: #fff;
} */
/* .enrich__card__img img:hover {
  height: 90px;
  width: 90px;
  background-color: green;
} */

.our__team__card .card {
  min-height: 265px;
  max-height: 285px;
  /* background-color: #FBFFF8; */
  background-color: #ffffff;
  border: 0px solid #fff !important;
}

.our__team__card__img img {
  height: 160px;
  width: 160px;
  align-items: center;
  border-radius: 50%;
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}

.our__team__card__title {
  min-height: 30px !important;
  max-height: 65px !important;
  overflow: hidden;
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.our__team__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.our__team__card__discription {
  color: #0d2c49c4;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 120%;
  min-height: 35px !important;
  max-height: 45px !important;
  overflow: hidden;
}

.advisor__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.advisor__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.advisor__card__discription {
  color: #0d2c49c4;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 150%;
  text-align: justify;
  overflow: hidden;
  overflow-y: auto;
  min-height: 140px;
  max-height: 150px;
  padding: 12px;
}

/* scroll style  for alergy and reaction  */
.g-doc-scroll::-webkit-scrollbar {
  width: 5px;
}

.g-doc-scroll::-webkit-scrollbar-track {
  background: #fff !important;
  border-left: 1px solid #ddd;
}

.g-doc-scroll::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 20%) !important;
  border-radius: 10px !important;
  padding: 2px;
}

/* scroll style   */

.book__app__bg,
.speciality__bg {
  margin-top: 40px;
  background: #4d9621;
}

.book__app {
  padding: 20px 106px;
}

.book__app__text {
  color: #fff;
  text-align: center;
  line-height: 173%;
}

.header__speciality__text {
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.speciality {
  padding: 20px 0px;
}

.speciality__specific {
  padding: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #fff;
}

.specialty__specific__card .card {
  min-height: 100px;
  max-height: 100px;
  background-color: #ffffff;
  border: 0px solid #fff !important;
}

.specialty__specific__card__discription {
  color: #0d2c49;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 120%;
  min-height: 50px !important;
  max-height: 130px !important;
  overflow: hidden;
}

.specialty__specific__card__title {
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.specialty__specific__card__title__percent {
  margin-top: 14px;
  font-weight: 600;
  color: #ff0000;
}

.email__text {
  font-size: 500;
  font-size: 20px;
  color: #0d2c49;
}

/* .email__demo__today {
  margin-top: 20px;
  padding-left: 198px;
} */

.email__demo__today .bt__demo__today {
  background: #cf5a5a;
  text-align: center;
  color: white;
  border: 1px solid #cf5a5a !important;
  border-radius: 10px;
  width: 64%;
  /* padding: 0.75rem; */
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* ehr end */

/* pm start  */
.laboratory {
  margin-top: 0px;
}

.header__description .green__color {
  color: #5aab46;
}

.macrohealth__card__pm .card {
  min-height: 355px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.keep__patients__card .card {
  min-height: 180px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.medical__image__doc__mange__card .card {
  min-height: 345px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.hpatients__card__title {
  color: #5aab46;
  font-weight: 500;
  line-height: 120%;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  min-height: 20px !important;
  max-height: 50px !important;
  overflow: hidden;
}

.header__card__discription__pm {
  color: #0d2c49;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 13px;
  line-height: 120%;
  min-height: 143px !important;
  max-height: 125px !important;
  overflow: hidden;
}

.laboratory__manage {
  border: 0.5px solid #5aab46;
  min-height: 465px;
  max-height: 100px;
  padding: 40px;
}

/* pm end */

/* e-commerce-medicine start  */
.macro__ecommerce img {
  width: 100%;
  height: 215px;
  padding: 0px 50px;
}

.pharmacy__marketplace img {
  width: 100%;
  height: 490px;
  padding: 0px 210px;
}

.moreover__unique__benefits__card .card {
  min-height: 185px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.lab__agent__text {
  color: #ff0000;
}

.lab__agent__mb {
  margin-bottom: 0px !important;
}

/* e-commerce-medicine end  */

/* webinars start  */

.webinars__card .card {
  min-height: 400px !important;
  max-height: 100px !important;
  overflow: hidden;
  z-index: 1;
}

/* .webinars__card img {
  width: 100%;
  height: 75px;
} */

.webinars__card__title {
  font-size: 16px;
  min-height: 40px !important;
  /* max-height: 50px !important; */
  overflow: hidden;
  text-align: center;
  color: #4d9621;
}

.webinars__card__description {
  color: #0d2c49;
  margin-top: 5px;
  font-size: 14px;
  line-height: 120%;
  text-align: justify;
  min-height: 50px !important;
  max-height: 125px !important;
  overflow: hidden;
}

.webinars__btn__load__more {
  background: #4d9621;
  border-radius: 10px;
  font-size: 14px;
  padding: 6px 40px;
  color: white !important;
}

.webinars__img img {
  width: 100%;
  height: 400px;
}

.webinars__date__text {
  color: #4d9621;
}

.webinars__detials p {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 10px;
  font-size: 15px;
  line-height: 173%;
  text-align: justify;
}

.header__webinars__description {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 0px;
  font-size: 15px;
  line-height: 173%;
  text-align: justify;
}

.webinars__right {
  border: 1px solid #adb5bd;
  padding: 10px;
}

.webinars__title {
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  color: #4d9621;
}

.webinars__list ul {
  padding-left: 20px !important;
}

.webinars__list ul li {
  color: rgba(0, 0, 0, 0.75);
  font-weight: 400;
  font-size: 15px;
  line-height: 173%;
}

.webinars__date__title {
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  color: #0d2c49;
}

.webinars__time__text {
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  color: #0d2c49;
}

.webinars__social__media {
  margin-top: 10px;
  padding: 10px;
}

.webinars__social__icon {
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: start;
}

.header__link {
  line-height: 180%;
  color: #0d2c49;
}

.header__link span {
  border-bottom: 1px solid #fff;
}

.social__icons__color {
  color: #4d9621;
}

/* start  */
.ht__social a {
  margin-top: 14px;
  background: #fff;
  color: #4d9621;
  height: 30px;
  line-height: 30px;
  width: 30px;
  font-size: 15px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4d9621;
}

.ht__social .facebook:hover {
  background: #4267b2;
  color: #fff;
}

.ht__social .twitter:hover {
  background: #1da1f2;
  color: #fff;
}

.ht__social .linkedin:hover {
  background: #0077b5;
  color: #fff;
}

.ht__social .whatsapp:hover {
  background: #25d366;
  color: #fff;
}

.ht__social .instagram:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #fff;
}

.ht__social .pinterest:hover {
  background: #e60023;
  color: #fff;
}

.ht__social .youtube:hover {
  background: #e60023;
  color: #fff;
}

/* end */
/* webinars end  */
.inspiration__div {
  padding: 0px 130px !important;
}

.margin__top {
  margin-top: 65px;
}

.inspiration strong {
  font-size: 18px;
}

.inspiration {
  border: 0.5px solid #5aab46;
  min-height: 290px;
  max-height: 100px;
  padding: 40px;
}

.inspiration__description {
  color: #0d2c49;
  line-height: 173%;
  font-size: 15px;
  text-align: justify;
  overflow: hidden;
  min-height: 220px;
  max-height: 100px;
}

.inspiration__span {
  color: #4d9621;
}

.h__business__plan {
  margin: 145px 0px;
}

.our__business__plan {
  background: #4d9621;
  padding: 20px;
}

.business__description {
  color: #fff;
  line-height: 173%;
  font-size: 15px;
  text-align: justify;
  margin-bottom: 0px !important;
}

.business__plan__card .card {
  min-height: 140px;
  max-height: 70px;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: #4d9621;
  overflow: hidden;
}

.business__plan__description {
  color: #fff;
  line-height: 173%;
  font-size: 15px;
  text-align: center;
  margin-bottom: 0px !important;
}

.target__market__card .card {
  min-height: 112px;
  max-height: 70px;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  overflow: hidden;
}

.target__market__description {
  color: #0d2c49;
  line-height: 173%;
  font-size: 15px;
  text-align: center;
  margin-bottom: 0px !important;
}

.our__business__goal img {
  width: 100%;
  height: 470px;
  padding: 0px 20px;
}

.busines__goals__bg__body {
  background: #264868;
}

.busines__goals__bg__body .card {
  background-color: #264868 !important;
  border: none !important;
}

.busines__goals__bg__body .card-body {
  padding: 1rem 0rem !important;
}

.business__goals__description {
  color: #fff;
  line-height: 173%;
  font-size: 15px;
  text-align: start;
  margin-bottom: 0px !important;
}

.header__unique__features {
  font-weight: 400;
  color: #4d9621;
  line-height: 173%;
}

.unique__features__description ul {
  list-style: circle;
}

.unique__features__description ul li {
  font-size: 15px;
  line-height: 173%;
  text-align: justify;
  color: #0d2c49c4;
}

.doctor__examining__patient {
  padding-right: 60px;
}

.doctorExamining__patient__img img {
  width: 100%;
  height: 240px;
}

.mims__logo__img img {
  width: 100%;
  height: 90px;
}

.qr__code__concept__img img {
  width: 100%;
  height: 210px;
}

.flag__bangladesh__img img {
  width: 100%;
  height: 210px;
}

.data__safety__security {
  margin-top: 95px;
}

.data__safety__security__img img {
  width: 100%;
  height: 300px;
}

.data__safety__card .card {
  min-height: 175px;
  max-height: 70px;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  overflow: hidden;
}

.data__safety__card__title {
  color: #4d9621;
  font-weight: 500;
  line-height: 130%;
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.data__safety__card__description {
  margin-top: 20px;
  color: #0d2c49;
  line-height: 173%;
  font-size: 15px;
  margin-bottom: 0px !important;
  text-align: center;
  overflow: hidden;
  min-height: 85px;
  max-height: 100px;
}

.why__choose__us {
  border: 0.5px solid #5aab46;
  min-height: 600px;
  max-height: 100px;
  padding: 40px;
}

.why__choose__us__title {
  color: #5aab46;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.why__choose__us__description {
  color: #0d2c49;
  line-height: 173%;
  font-size: 15px;
  text-align: justify;
}

.neurosurgeons__fetures h5 {
  font-size: 1.17rem;
}

/* specialities start  */
.general__practice__card .card {
  min-height: 180px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 5%);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.lab__agent__card .card {
  min-height: 130px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 5%);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.header__gp__card__subtitle {
  color: #212529;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  min-height: 40px !important;
  max-height: 50px !important;
  overflow: hidden;
}

.specialities__banner {
  position: relative;
  color: white;
}

.specialities__banner__img img {
  width: 100%;
  height: 500px;
}

.s__banner__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 235%);
}

.specialities__banner__title {
  text-align: center;
  color: #69b128;
  font-size: 40px;
  font-weight: 600;
}

.specialities__banner__text__h {
  bottom: 0;
  color: hsla(0, 23%, 95%, 0.856);
  position: absolute;
  width: 100%;
}

.specialities__banner__text {
  background: rgba(0, 0, 0, 0.22);
  bottom: 0;
  color: hsla(0, 23%, 95%, 0.856);
  padding: 8px 25px 20px 25px;
  width: 100%;
}

.specialities__banner__description {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 165%;
  letter-spacing: 0.03em;
  padding: 0px 110px;
}

.solution__banner__description {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 165%;
  letter-spacing: 0.03em;
  padding: 0px 110px;
  margin-bottom: 0px;
}

/* specialities end  */

/* general practice start */
.header__description__gp {
  color: #0d2c49;
  line-height: 173%;
  font-size: 20px;
  text-align: center;
}

.h__description {
  color: #0d2c49;
  line-height: 173%;
  font-size: 15px;
  text-align: center;
}

.gp {
  margin-top: 95px;
}

.gp__img {
  padding: 0px 50px;
}

.gp__img img {
  width: 100%;
  height: 500px;
}

.bg__card__img img {
  height: 70px;
  width: 100%;
  align-items: center;
  /* border-radius: 50%; */
}

.psychological__card__img img {
  height: 60px;
  width: 60px;
  align-items: center;
  /* border-radius: 50%; */
}

.pathology__card {
  margin-top: 60px;
}

.pathology__card .card {
  min-height: 65px !important;
  max-height: 50px !important;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 5%);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

/* general practice end */

/* pricing start  */
.pricing__table .pricing__table__td {
  background: #4d9621;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.pricing__table .table__data {
  color: #0d2c49;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.btn__pricing__table {
  background: #5aab46;
  border-radius: 6px;
  padding: 6px 30px;
  font-size: 14px;
  font-weight: 400;
  color: white !important;
  text-decoration: none;
}

.pricing__card .card {
  /* min-height: 330px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #5AAB46;
  border-radius: 8px;
  z-index: 1; */
  min-height: 430px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.pricing__card .pricing__mt {
  margin-top: 25px;
}

.pricing__card .hpricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.gp__card__body {
  padding: 0px 20px;
}

.pricing__body {
  min-height: 305px !important;
  max-height: 200px !important;
  overflow: hidden;
}

.pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.pricing__card .pricing__mt {
  margin-top: 25px;
}

.gdpricing__card .card {
  min-height: 460px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.gdpricing__card .pricing__mt {
  margin-top: 25px;
}

.gdpricing__card .gdpricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.gdpricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 50px;
  max-height: 30px;
}

.gdpricing {
  min-height: 50px;
  max-height: 20px;
}

.gdpricing__body {
  min-height: 225px !important;
  /* max-height: 150px !important; */
  overflow: hidden;
}

.gdpricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.gdpricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.gdpricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.gdpricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.pricing__all__txt {
  font-size: 18px;
}

.glpricing__card .card {
  min-height: 540px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.glpricing__card .pricing__mt {
  margin-top: 25px;
}

.glpricing__card .glpricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.glpricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 68px;
  max-height: 30px;
}

.glpricing__body {
  min-height: 282px;
  max-height: 150px;
  overflow: hidden;
}

.glpricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.glpricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.glpricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.glpricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.glpricing {
  min-height: 50px;
  max-height: 20px;
}

.gph__pricing__card .card {
  min-height: 450px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.gph__pricing__card .pricing__mt {
  margin-top: 25px;
}

.gph__pricing__card .gph__pricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.gph__pricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 50px;
  max-height: 30px;
}

.gph__pricing {
  min-height: 50px;
  max-height: 20px;
}

.gph____card__body {
  padding: 0px 20px;
}

.gph__pricing__body {
  min-height: 225px !important;
  max-height: 150px !important;
  overflow: hidden;
}

.gph__pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.gph__pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.gph__pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.gph__pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.eph__pricing__card .card {
  min-height: 345px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.eph__pricing__card .eph__pricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.eph__pricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 68px;
  max-height: 30px;
}

.eph__card__body {
  padding: 0px 20px;
}

.eph__pricing__body {
  min-height: 113px;
  max-height: 100px;
  overflow: hidden;
}

.eph__pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.eph__pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.eph__pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.eph__pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.eph__pricing {
  min-height: 50px;
  max-height: 20px;
}

.mpl__pricing__card .card {
  min-height: 230px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.mpl__pricing__card .mpl__pricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.mpl__pricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 68px;
  max-height: 30px;
}

.mpl__card__body {
  padding: 0px 20px;
}

.mpl__pricing__body {
  min-height: 70px;
  max-height: 30px;
  overflow: hidden;
}

.mpl__pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.mpl__pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.mpl__pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.mpl__pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.mpl__pricing {
  min-height: 50px;
  max-height: 20px;
}

.all__pricing__card .card {
  min-height: 290px;
  max-height: 260px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.all__pricing__card .all__pricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.all__pricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 68px;
  max-height: 30px;
}

.all__card__body {
  padding: 0px 20px;
}

.all__pricing__body {
  min-height: 70px;
  max-height: 30px;
  overflow: hidden;
}

.all__pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.all__pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.all__pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.all__pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.all__pricing {
  min-height: 50px;
  max-height: 20px;
}

.la__pricing__card .card {
  min-height: 285px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.la__pricing__card .la__pricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.la__pricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 68px;
  max-height: 30px;
}

.la__card__body {
  padding: 0px 20px;
}

.la__pricing__body {
  min-height: 70px;
  max-height: 30px;
  overflow: hidden;
}

.la__pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.la__pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.la__pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.la__pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.la__pricing {
  min-height: 50px;
  max-height: 20px;
}

.telehealth__pricing__card .card {
  min-height: 295px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
}

.telehealth__pricing__card .telehealth__pricing span {
  color: #0d2c49;
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
  margin-top: 3px;
  margin-bottom: 0rem !important;
}

.telehealth__pricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  min-height: 68px;
  max-height: 30px;
}

.telehealth__card__body {
  padding: 0px 20px;
}

.telehealth__pricing__body {
  min-height: 175px;
  max-height: 100px;
  overflow: hidden;
}

.telehealth__pricing__body ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #0d2c49;
}

.telehealth__pricing__body ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #0d2c49;
  margin-bottom: 3px !important;
}

.telehealth__pricing__body .gd__text {
  text-align: center;
  color: #4c4e4f;
}

.telehealth__pricing__body .gd__desc__txt {
  text-align: center;
  color: #4c4e4f;
}

.telehealth__pricing {
  min-height: 50px;
  max-height: 20px;
}

.hpricing__card__title {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 25px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.hpricing__card__subtitle {
  color: #0d2c49;
  font-weight: 500;
  line-height: 120%;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
}

.hpricing__card__subtitle .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  min-width: 35px;
  padding: 0px 5px;
}

.css-1k51tf5-MuiTooltip-tooltip {
  font-size: 0.6875rem;
  max-width: 145px !important;
  text-align: center !important;
}

.btn__pricing {
  background: #5aab46;
  border-radius: 6px;
  padding: 8px 30px;
  font-size: 14px;
  font-weight: 400;
  color: white !important;
  text-decoration: none;
  text-align: center;
}

.pricing__nav .nav__bg {
  border-radius: 0.25rem;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}

.pricing__nav ul li {
  padding: 0px 0px 0px 0px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #2b5e1e !important;
  border-radius: 0 !important;
}

.pricing__link {
  display: block;
  padding: 0.4rem 1rem;
  font-size: 15px;
  color: #fff !important;
  background-color: #79b93e !important;
  border-radius: 0 !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.pricing__link:hover {
  background-color: #2b5e1e !important;
}

/* pricing end */

/* psychological__card start*/
.psycho__ques__card .card {
  min-height: 70px;
  max-height: 50px;
  border: 0px solid #fff !important;
  border-radius: 4px;
  /* background: #42b509; */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.psycho__ques__card .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 590px !important;
}

.psycho__ques__card .modal-header {
  padding: 0.3rem 1rem !important;
}

.psycho__ques__card img {
  height: 440px;
  width: 100%;
  align-items: center;
  align-items: center;
}

.psycho__ques__card {
  padding: 0px 0px !important;
}

.psycho__ques__card__title {
  color: #fff;
  white-space: nowrap;
  font-weight: 600;
  line-height: 120%;
  font-size: 18px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
  background: #5aab46;
  padding: 12px 60px;
  border: 0px solid #5aab46;
  width: 100%;
  border-radius: 25px;
  cursor: pointer;
}

.btn__psycho__ques {
  background: #5aab46;
  padding: 12px 60px;
  border: 0px solid #5aab46;
  width: 100%;
  border-radius: 25px;
}

.modal__btn__psycho__ques {
  background: #4d9621;
  color: #fff;
  padding: 6px 20px;
  border: 0px;
  border-radius: 6px;
}

.psychological__questionnaires .card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.psychological__questionnaires .card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.psychological__questionnaires .card h3 {
  font-weight: 600;
}

.psychological__questionnaires .card img {
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.psychological__questionnaires .card-1 {
  background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

/* psychological__card end*/

/* react modal consultaion start */
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 4px;
  width: 75%;
  height: 500px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1050;
}

.close_btn {
  background: #c9cecb;
  padding: 8px 11px;
  border-radius: 50%;
}

.close_btn:hover {
  background: #c9edde;
}

.modal__body .card-body {
  flex: 1 1 auto;
  padding: 0.3rem 0.5rem !important;
}

.modal__body hr {
  margin: 0.3rem 0 !important;
}

.h__modal__text {
  color: rgba(0, 0, 0, 0.75);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px !important;
}

.modal__close__btn {
  margin-bottom: 0px !important;
}

.btn__qr {
  background: #4d9621;
  padding: 20px 60px;
  width: 100%;
  border: none;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  outline: none;
  border-radius: 14px;
  margin-top: 10px;
}

.btn__qr:hover {
  cursor: pointer;
  background: #4d9621;
  color: #fff;
}

.btn__qr:active {
  outline: none;
  border: none;
}

.btn__qr:focus {
  outline: none !important;
  box-shadow: none;
}

.psycho__quetion img {
  width: 100%;
  height: 490px;
}

.medicale__practice {
  margin-top: 65px;
  padding-left: 92px;
  width: 100%;
}

.card__img {
  position: relative;
  width: 50%;
  left: 50px;
  right: 50px;
  text-align: center;
}

.card__img img {
  height: 90px;
  width: 90px;
  align-items: center;
  /* border-radius: 50%; */
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}

/* .image {
  display: block;
  width: 100%;
  height: auto;
} */

/* .image:hover {
  background-color: #25D366;
} */

.overlay__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  /* background-color: #008CBA; */
}

.card__img:hover .overlay__img {
  opacity: 1;
}

/* .text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
} */

.client__login__card img {
  width: 50px;
  height: 55px;
  object-fit: contain;
}

.client__login__card .card {
  min-height: 150px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #ffffff;
  /* box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05); */
  border: 1px solid #5aab46;
  border-radius: 8px;
  z-index: 1;
  color: #4d9621;
  text-align: center;
}

.client__login__card .card:hover {
  background: rgba(105, 151, 41, 0.89);
  color: #ffffff;
}

.client__login__card .card-body:hover {
  color: #ffffff;
}

/* cleint login end  */

.telehealth__div img {
  width: 80%;
  height: 275px;
  padding: 10px 18px;
  border: 1px solid grey;
  border-radius: 6px;
}

.telehealth__description {
  color: #0d2c49;
  line-height: 173%;
  font-size: 17px;
  padding-right: 20px;
}

.col__width {
  flex: 0 0 auto;
  width: 11.666667% !important;
}

.how__it__process__cont {
  display: flex;
  justify-content: space-evenly;
}

.how__it__process__div {
  text-align: center;
  width: 160px;
}

.how__it__process__arrow {
  text-align: center;
  width: 100px;
}

.how__it__process {
  text-align: center;
}

.how__it__process img {
  height: 90px;
  width: 90px;
  align-items: center;
  text-align: center;
}

.it__process__img .arrow__img {
  width: 110px;
  height: 30px;
  margin-top: 33px;
}

.it__process__title {
  color: #000000;
  font-weight: 500;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0rem !important;
}

.modal__edu__guideline .table-active {
  background: #459645;
  color: #fff !important;
}

.modal__edu__guideline .modal__table__border {
  border-color: #459645 !important;
}

.modal__table__border th,
td {
  font-size: 15px;
}

.modal__edu__guideline .modal__guideline__btn {
  color: #fff;
  background-color: #2eae06;
  border-color: #2eae06;
  padding: 2px 8px;
  margin: 0px 2px;
  outline: none;
}

.modal__edu__guideline .modal__guideline__btn:hover {
  color: #fff;
  background-color: #1e6608;
  border-color: #1e6608;
}

.modal__edu__guideline .btn-check:focus + .btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.modal__edu__guideline .modal__det__btn {
  color: rgba(0, 0, 0, 0.75);
  background-color: #ffffff;
  border-color: #258a07;
  padding: 2px 8px;
  margin: 0px 2px;
  outline: none;
}

.modal__edu__guideline .modal__det__btn:hover {
  color: #ffffff;
  background-color: #258a07;
  border-color: #258a07;
}

.modal__edu__guideline {
  padding-left: 50px;
}

.modal__edu__guideline .btn-check:focus + .btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.h__modal__text span {
  border-bottom: 2px solid #5aab46;
  padding-bottom: 3px;
}

.digipatient__img {
  text-align: center;
}

.digipatient__img img {
  padding-right: 80px;
  width: 100%;
  height: 370px;
}

.keyhr__img img {
  height: 90px;
  width: 100%;
  align-items: center;
}

.keyhr__card {
  min-height: 100%;
}

.keyhr__card .card {
  min-height: 300px;
  max-height: 100px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.keyhr__card img {
  width: 70px;
  height: 75px;
}

.keyhr__card .macrohealth__mt {
  margin-top: 25px;
}

.tele__medi__mb {
  margin-bottom: 20px;
}

.stepper__specialist .css-zjf1nq-MuiPaper-root {
  padding-left: 0px;
  justify-content: center;
}

.stepper__specialist .css-114p2tk-MuiMobileStepper-dot {
  width: 5px;
  height: 5px;
}

.show__small__device {
  display: none;
}

@media only screen and (max-width: 768px) {
  .show__big__device {
    display: none;
  }

  .show__small__device {
    display: contents;
  }

  .how__it__process__cont {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px 0px 0px;
  }

  .how__it__process__div {
    text-align: center;
    width: 34px;
  }

  .how__it__process__div .card-body {
    padding: 8px 2px !important;
  }

  .how__it__process__arrow {
    text-align: center;
    width: 12px;
  }

  .tele__medi__mb {
    margin-bottom: 4px;
  }

  .how__it__process {
    text-align: center;
  }

  .how__it__process img {
    height: 45px;
    width: 45px;
  }

  .it__process__img .arrow__img {
    width: 20px;
    height: 10px;
    margin-top: 20px;
  }

  .it__process__title {
    color: #000000;
    font-weight: 400;
    line-height: 120%;
    font-size: 11px;
    text-align: center;
    margin-bottom: 0rem !important;
  }

  .telehealth__div img {
    width: 100%;
    height: 230px;
    padding: 10px 15px;
  }

  .mymodal {
    width: 90%;
  }

  /* For desktop: mobile:*/
  .banner__img img {
    width: 100%;
    height: 190px;
  }

  .banner__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -52%);
    border-left: 3px solid #f68823;
    padding-left: 10px;
    width: 95%;
  }

  .banner__title {
    font-size: 14px;
    font-weight: 600;
  }

  .banner__title2 {
    font-size: 20px;
    font-weight: 600;
  }

  .banner__subtitle {
    font-size: 14px;
    font-weight: 300 !important;
    margin-top: 4px;
    margin-bottom: 4px !important;
  }

  .banner__description {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 120%;
  }

  .testimonials__carousel__box__card .card {
    min-height: 600px !important;
    max-height: 100px !important;
  }

  .tesimonials_h {
    margin: 0px;
  }

  .testimonials__inside__card .card2 {
    min-height: 100px !important;
    max-height: 100px !important;
    padding: 0px;
  }

  .testimonials__img {
    padding: 0px 62px;
  }

  .macro {
    margin: 0px;
  }

  .lab__door {
    margin: 0px;
  }

  .solution__macro {
    text-align: justify;
  }

  .header__text__martplace {
    font-weight: 600 !important;
    font-size: 15px;
  }

  .header__text {
    font-weight: 600 !important;
    font-size: 18px;
  }

  .header__text__normal {
    font-size: 18px;
  }

  .btn_learn_more {
    border-radius: 10px;
    font-size: 14px;
  }

  .macrohealth__card .card {
    min-height: 330px;
    max-height: 100px;
    overflow: hidden;
    z-index: 1;
  }

  .macrohealth__card__pm .card {
    min-height: 345px !important;
    max-height: 100px !important;
    overflow: hidden;
    z-index: 1;
  }

  .macrohealth__card img {
    width: 70px;
    height: 75px;
  }

  .header__card__title {
    font-size: 16px;
  }

  .header__card__subtitle {
    min-height: 40px !important;
    max-height: 50px !important;
    overflow: hidden;
  }

  .call__us__img {
    text-align: center;
  }

  .call__us__img img {
    width: 150px;
    height: 120px;
  }

  .call__us {
    margin: 8px 0px;
  }

  .call__us__text {
    font-size: 15px;
    text-align: center;
    margin-top: 0px;
  }

  .about__img img {
    width: 100%;
    height: 300px;
    padding: 0px 20px;
  }

  .hreq__text {
    font-size: 17px;
  }

  .hreq__subtext {
    font-size: 15px;
    font-weight: 100;
  }

  .hreq__about__text {
    font-size: 17px;
    text-align: center;
  }

  .news__margin__top {
    margin-top: 20px !important;
  }

  .hreq__about,
  .h__subtxt {
    text-align: center;
    margin-bottom: 0px;
  }

  .mht__excited,
  .mb__req,
  .mt__req__demo {
    margin-top: 0px;
  }

  .feature__img__2 {
    margin-top: 20px;
  }

  .feature__img__2 img {
    width: 100%;
    height: 200px;
  }

  .book__app {
    /* padding: 19px 1px; */
    padding: 19px 20px;
  }

  .book__app__text {
    font-size: 15px;
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    padding: 0px 25px;
  }

  .ehr {
    margin-top: 0px;
  }

  .ehr__img {
    padding-left: 0px;
    margin-top: 25px;
  }

  .ehr__img img {
    width: 100%;
    height: 210px;
  }

  .accro__div {
    padding-left: 3px;
  }

  .accrodian__title {
    padding-left: 10px;
  }

  .accrodian__details {
    padding-left: 2px;
  }

  .accrodian__details p {
    margin-bottom: 0px !important;
    padding-right: 18px;
  }

  .maintain__vital__img {
    padding-left: 18px;
  }

  .maintain__vital__img img {
    width: 100%;
    height: 246px;
    padding: 0px 85px;
  }

  .mpm {
    margin-top: 20px;
  }

  .mpm__margin {
    margin-top: 25px;
  }

  .mpm__img {
    position: relative;
    text-align: center;
    color: white;
  }

  .mpm__img img {
    width: 100%;
    height: 220px;
  }

  .mpm__overlay__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mpm__youtube__icons {
    background: #0d2c49;
    border-radius: 10px;
    padding: 0px 10px;
  }

  .mpm__youtube__icons img {
    width: 16px;
    height: 19px;
    margin: 10px;
  }

  .contact__world__map {
    padding: 0px;
  }

  .contact__world__map img {
    width: 100%;
    height: 265px;
  }

  .contact__world__overlay__div {
    position: relative;
  }

  .contact__office__address {
    padding: 0px;
  }

  .contact__address1 {
    padding: 10px 10px;
    /* background: #F6F6F6; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .contact__address2 {
    padding: 10px 10px;
    /* background: #F6F6F6; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .btn__contact {
    padding: 4px 15px;
  }

  .request__demo__text {
    font-size: 15px;
    margin-bottom: 0px !important;
  }

  .request__demo__div {
    padding: 0px 44px;
  }

  .request__demo {
    margin: 0px 12px;
    padding: 10px 10px;
  }

  .request__demo__hr hr {
    margin: 0.5rem 0 !important;
  }

  .request__demo__left {
    padding: 0px 10px;
  }

  .header__demo__title {
    font-size: 18px;
  }

  .header__demo__description {
    line-height: 160%;
  }

  .demo__list ul li {
    line-height: 160%;
  }

  .demo__list ul {
    padding-left: 17px !important;
  }

  .btn__request {
    padding: 6px 30px;
    font-size: 15px;
  }

  .webinars__card .card {
    min-height: 390px !important;
    max-height: 100px !important;
    overflow: hidden;
    z-index: 1;
  }

  .webinars__card__title {
    font-size: 16px;
    min-height: 40px !important;
    max-height: 50px !important;
  }

  .webinars__card__description {
    min-height: 50px !important;
    max-height: 125px !important;
    overflow: hidden;
  }

  .webinars__btn__load__more {
    padding: 6px 20px;
  }

  .inspiration__div {
    padding: 0px 5px !important;
  }

  .margin__top {
    margin-top: 20px;
  }

  .inspiration {
    min-height: 270px;
    max-height: 100px;
    padding: 20px;
  }

  .inspiration__description {
    overflow: hidden;
    min-height: 240px;
    max-height: 100px;
  }

  .h__business__plan {
    margin: 0px;
    text-align: center;
  }

  .our__business__plan {
    padding: 20px;
  }

  .business__goals__description {
    text-align: center;
  }

  .header__unique__features {
    font-size: 17px;
    line-height: 173%;
  }

  .doctor__examining__patient {
    padding-right: 0px;
  }

  .doctorExamining__patient__img img {
    width: 100%;
    height: 240px;
  }

  .data__safety__security {
    margin-top: 0px;
  }

  .data__safety__security__img img {
    width: 100%;
    height: 250px;
  }

  .data__safety__card .card {
    min-height: 200px;
    max-height: 100px;
    overflow: hidden;
  }

  .unique__features__description ul li {
    font-size: 15px;
    line-height: 130%;
    padding-right: 9px;
    margin-bottom: 5px;
  }

  .why__choose__us {
    min-height: 525px;
    max-height: 100px;
    padding: 20px;
  }

  .why__choose__us__description {
    line-height: 150%;
  }

  .general__practice__card .card {
    min-height: 190px !important;
    max-height: 100px !important;
    overflow: hidden;
  }

  .speciality {
    padding: 20px 20px;
  }

  .specialities__banner__img img {
    width: 100%;
    height: 190px;
  }

  .specialities__banner__text {
    padding: 12px 2px;
  }

  .specialities__banner__title {
    font-size: 20px;
    font-weight: 600;
  }

  .specialities__banner__description {
    font-size: 14px;
    line-height: 120%;
    padding: 0px 10px;
  }

  .btn__ehr {
    padding: 8px 10px;
    font-size: 14px;
  }

  .header__description__gp {
    color: #0d2c49;
    line-height: 120%;
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
  }

  .ht__text {
    font-size: 15px;
  }

  .gp {
    margin-top: 0px;
  }

  .h__description {
    line-height: 120%;
    font-size: 14px;
    text-align: justify;
  }

  .ehr__img1 {
    margin-top: 30px;
  }

  .ehr__img1 img {
    width: 100%;
    height: 230px;
  }

  .gp__img {
    padding: 0px;
  }

  .gp__img img {
    width: 100%;
    height: 230px;
  }

  .email__demo__today {
    padding-left: 0px;
    margin-top: 0px;
    text-align: center;
  }

  .email__demo__today .bt__demo__today {
    margin-top: 0px;
    padding: 0.3rem;
    font-size: 15px;
  }

  .header__description {
    line-height: 130%;
    font-size: 14px;
  }

  .mpms__mt {
    margin-top: 17px;
  }

  .faq-section__lab .accordion-flush .accordion-item .accordion-button {
    font-size: 15px;
    padding-right: 10px;
  }

  .our__business__goal img {
    width: 100%;
    height: 255px;
  }

  /* .searcht__icon__left img{
  padding: 0px 0px 0px 4px;
  top: 0px;
  left: -71px;
  position: relative;
  z-index: -200;
  height: 25px;
}  */
  /* .searcht__icon__right img{
  padding: 0px 0px 0px 0px;
  top: 16px;
  right: -71px;
  position: relative;
  z-index: -200;
  height: 25px;
}  */
  .searcht__icon__left img,
  .searcht__icon__right img {
    height: 25px;
  }

  /* .searcht__icon__left{
  padding: 0px 0px 0px 4px;
  top: 0px;
  left: -71px;
  position: relative;
  z-index: -200;
  height: 25px;
}  */
  .searcht__icon__left {
    padding: 0px 0px 0px 4px;
    top: -14px;
    left: 1px;
    position: relative;
    z-index: -200;
    height: 25px;
  }

  .searcht__icon__right {
    padding: 0px 0px 0px 5px;
    top: 2px;
    left: -2px;
    position: relative;
  }

  .searcht__icon {
    top: 17px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
  }

  .searchb__icon {
    margin-top: 14px;
    padding: 0px 18px;
  }

  .searchb__icon__right {
    padding: 0px 40px 0px 0px;
  }

  .mht__rms {
    margin-top: 0px;
  }

  .mht__excited__description {
    font-size: 16px;
    text-align: center;
    padding-right: 0px;
  }

  .medicale__practice {
    margin-top: 8px;
    padding-left: 16px;
    width: 100%;
  }

  .call__us__text__no {
    text-align: center;
    padding-left: 0px;
    font-size: 15px;
  }

  .our__mt {
    margin-top: 50px;
  }

  .world__partner__carousel {
    padding: 0px 20px;
  }

  .world__partner__carousel .slick-dots li {
    width: 3px !important;
  }

  .web__carousel__box__card .card {
    min-height: 120px !important;
    max-height: 100px !important;
  }

  .web__carousel__box__card .slick-dots {
    bottom: -35px !important;
  }

  .web__carousel .slick-prev:before,
  .web__carousel .slick-next:before {
    color: #484848 !important;
    font-size: 20px !important;
  }

  .web__carousel .slick-dots li {
    width: 15px !important;
  }

  .web__carousel .slick-dots li button:before {
    font-size: 6px !important;
  }

  .medical__practice ul li p {
    font-size: 14px;
  }

  .medical__practice {
    min-height: 167px !important;
    max-height: 80px !important;
    overflow: hidden;
  }

  .solution__banner__description {
    font-size: 15px;
    line-height: 130%;
    padding: 0px 10px;
  }

  .header__description2 {
    line-height: 130%;
    font-size: 14px;
  }

  .digipatient__img img {
    padding-right: 0px;
    width: 80%;
    height: 220px;
  }

  .keyhr__card .card {
    min-height: 300px;
    max-height: 100px;
    overflow: hidden;
  }

  .header__speciality__text {
    font-weight: 600;
    font-size: 18px;
  }

  .specialty__specific__card .card {
    min-height: 65px;
    max-height: 70px;
  }

  .specialty__specific__card__title {
    font-size: 15px;
    font-weight: 600;
  }

  .speciality__specific {
    margin-bottom: 0px;
  }

  .great__doc ul li p {
    font-size: 14px;
    font-weight: 500;
  }

  .pr__img img {
    width: 100%;
    height: 220px;
  }

  .pathology__card {
    margin-top: 15px;
  }

  .pathology__card .card-body {
    padding: 22px 0px !important;
  }

  .pathology__htitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px !important;
  }

  .app__mangement__img img {
    height: 70px;
    width: 100%;
  }

  .pharmacy__marketplace img {
    width: 100%;
    height: 305px;
    padding: 0px 2px;
  }

  .news__letter__text {
    font-size: 18px;
    font-weight: 600;
    margin-top: 16px;
  }

  .psycho__ques__card {
    padding: 0px 54px !important;
  }

  .psycho__ques__card__title {
    font-size: 15px;
    padding: 8px 55px;
  }

  .business__plan__card .card {
    min-height: 115px;
    max-height: 70px;
    overflow: hidden;
  }

  .pricing__all__txt {
    font-size: 16px;
  }

  .hpricing__card__title {
    font-size: 20px;
  }

  .glpricing__card .glpricing span {
    font-size: 12px;
    font-weight: 500;
    margin-top: -1px;
  }

  .mymodal {
    transform: translate(-50%, -76%);
  }

  .helpc__searchbar .MuiOutlinedInput-input {
    padding: 6px 2px;
  }

  .helpc__searchbar .MuiInputBase-fullWidth {
    width: 80%;
  }

  .helpc__searchbar .MuiSvgIcon-root {
    font-size: 25px;
  }

  .helpc__searchbar .MuiIconButton-root {
    margin-bottom: 5px;
  }

  .eco__txt {
    font-size: 18px;
    text-align: center;
  }

  .testi__margin__top {
    margin-top: 0px;
  }

  .card__img {
    position: none;
    width: 100%;
    left: 0px;
    right: 0px;
    text-align: center;
  }
}

.pdf-viewer {
  overflow: auto;

  max-height: 88vh;
}

.pdf-viewer ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.pdf-viewer ::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  cursor: pointer !important;
  border-radius: 6px;
}

.pdf-viewer ::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
  border-radius: 6px;
}
