/* .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 32px;
} */
.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 32px !important;
}

.stepper__img img {
    width: 100%;
    height: auto;
}


.g_doc{
    min-height: 350px;
    max-height: 340px;
    overflow: hidden;
    overflow-y: auto;
}
/* scroll style  for alergy and reaction  */
.g-doc-scroll::-webkit-scrollbar {
    width: 2px;
}
.g-doc-scroll::-webkit-scrollbar-track {
    background: #fff !important;
    border-left: 1px solid #ddd;
}
.g-doc-scroll::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 20%) !important;
    border-radius: 10px !important;
    padding: 2px;
}
/* scroll style   */