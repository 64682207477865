/* .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 32px;
} */
.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 32px !important;
}

.stepper__img img {
    width: 80%;
    height: 350px;
}

@media only screen and (max-width: 768px) {

    .stepper__img img {
        width: 90%;
        height: 240px;
    }
    /* .css-rnhwm .QontoStepIcon-completedIcon {
        width: 40px !important;
        height: 40px !important;
        padding: 10px !important;
    }
    .css-1i0vbm5 .QontoStepIcon-circle {
        width: 40px !important;
        height: 40px !important;
    } */
    .css-1v9cb5m-MuiStepConnector-root.MuiStepConnector-alternativeLabel {
        top: 10px;
        left: calc(-57% + 28px) !important;
        right: calc(43% + 28px) !important;
    }
}