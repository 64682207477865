.section-header {
    text-align: center;
    margin: 40px 0px 80px;
}

.section-header h3 {
    font-size: 22px;
    color: #2196f3;
    text-transform: uppercase;
}

.section-header h2 {
    font-size: 32px;
    color: #292929;
    position: relative;
}

.section-header h2:after {
    content: "";
    height: 3px;
    max-width: 100%;
    width: 120px;
    background: #2196f3;
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0px;
    margin: 0 auto;
}

.faq-section__lab .accordion-collapse {
    border: 0;
}

.faq-section__lab .accordion-button {
    padding: 15px 0px 15px;
    font-weight: bold;
    border: 0;
    font-size: 18px;
    color: #333333;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.faq-section__lab .accordion-button:focus {
    box-shadow: none;
}

.faq-section__lab .accordion-button:not(.collapsed) {
    background: none;
    color: #474849;
}

.faq-section__lab .accordion-body {
    padding: 15px 0px 15px;
}

.faq-section__lab .accordion-button::after {
    background-image: url('../../images/arrow_down1.png');
    background-repeat: no-repeat;
}

.faq-section__lab .accordion-button:not(.collapsed)::after {
    background-image: url('../../images/arrow_down1.png');
    transform: rotate(-180deg);
}
.webinars__card img {
    height: 190px !important;
}