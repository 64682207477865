.ht__text {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.ht__navbar .navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ht__navbar__logo .navbar-brand {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/* .nav-link {
  color: #8b8d8f !important;
} */
.navLink {
  color: #8b8d8f !important;
}
/* .nav__link{
  color: #8b8d8f !important;
} */
.btn_learn_more {
  background: #4D9621;
  border-radius: 8px;
  color: white !important;
}

.btn_learn_more:hover {
  color: white !important;
}


/* new submenu start */
.menu3 .drop__solutions {
  margin: 2px 0 !important;
  min-width: 47rem;
  transform: translateX(-70px);
  padding: 15px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  /* border: none !important; */
}
.menu3 .drop__solutions span:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  display: block;
  transition: all 0.3s ease;
  bottom: -22%;

}

.navbar__collapse .menu3 li:hover .drop__solutions {
  display: block;
}


.menu3 .drop__specialities{
  margin: 2px 0 !important;
  min-width: 47rem;
  transform: translateX(-193px); 
  padding: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.menu3 .drop__specialities span:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  display: block;
  transition: all 0.3s ease;
  bottom: -22%;
}
.navbar__collapse .menu3 li:hover .drop__specialities {
  display: block;
}


.menu3 .drop__resources{
  margin: 2px 0 !important;
  min-width: 47rem;
  transform: translateX(-329px); 
  padding: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.menu3 .drop__resources span:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  display: block;
  transition: all 0.5s ease;
  bottom: -22%;
}
.navbar__collapse .menu3 li:hover .drop__resources {
  display: block;
}

.hsubmenu__img img {
  width: 25px;
  height: 25px;
}
.hsubmenu__text p {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #8b8d8f !important;
}
.navbar__collapse .menu3 .dropdown-item.active, .dropdown-item:active {
  background-color: #e9ecef !important;
}
.navbar__collapse .drop__solutions .dropdown-item,
.navbar__collapse .drop__resources .dropdown-item,
.navbar__collapse .drop__specialities .dropdown-item {
  padding: 0.25rem 0rem !important;
}
/* .drop__solutions .dropdown-item:focus, 
.drop__solutions .dropdown-item:hover,
.drop__resources .dropdown-item:focus, 
.drop__resources .dropdown-item:hover, 
.drop__specialities .dropdown-item:focus, 
.drop__specialities .dropdown-item:hover {
  background-color: #f8f9fa !important;
} */
.menu3  li .big__submenu:hover{
  color: #272727 !important;
}
/* new submenu end */

.menu3 {
  list-style: none;
}

.menu3 li span {
  display: block;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}


/* Style 3: Just animate the :after element's width from 0 to 100% */

.navbar__collapse .menu3 li ul li {
  width: 100%;
}

.navbar__collapse .menu3 li:hover ul {
  display: block;
}

.navbar__collapse .navbar-dark .navbar-nav .drop__nav__link:focus,
.navbar-dark .navbar-nav .drop__nav__link:hover {
  color: #fff;
  background: #5AAB46 !important;
}

.navbar__collapse .drop__nav__link {
  color: #8b8d8f;
  font-size: 15px;
}

.menu3 li span:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  display: block;
  transition: all 0.9s ease;
  bottom: -8%;

}

.menu3 li span:hover::after {
  width: 100%;
  height: 2px;
  background-color: #569006;
}

.menu3 li span .nav-link {
  color: #726e6e;
}

.menu3 li span .nav-link:hover {
  color: #fff;
}

.menu3 li span i {
  padding-left: 8px;
}

.active-nav {
  color: #272727 !important;
  bottom: 9px;
}

.navbar-brand {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.navbar-dark .navbar-toggler {
  color: #42a108 !important;
  border-color: #42a108 !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("../../images/menu.png");
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1em !important;
  height: 1em !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.1rem !important;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.2rem !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1195px;
}

.navbar-dark .navbar-toggler {
  border-color: #42a108 !important;
  color: #42a108 !important;
}

@media only screen and (max-width: 768px) {
  .ht__text {
    font-size: 14px;
  }
  
  /* For desktop: mobile:*/
  /* .active-nav {
      color: none !important;
      bottom: 9px;
    }
    .menu3 li span:hover::after {
      width: 100%;
      height: 2px;
      background-color: rgb(209, 74, 74);
    } */
  .menu3 li span:hover::after {
    background-color: transparent;
  }

  .menu3 li span {
    padding-left: 10px;
  }

  #h_btn {
    padding-left: 10px;
  }

  .client_login {
    margin-top: 5px !important;
  }

  /* submenu start  */
.menu3 .drop__solutions,
.menu3 .drop__specialities,
.menu3 .drop__resources {
  margin: 0 !important;
  min-width: 7rem;
  padding: 5px;
  transform: translateX(0px) !important;
}
/* submenu end  */
}